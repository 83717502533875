<template>
	<b-row class="div-component my-3">
		<b-overlay
			:show="!ready"
			rounded="sm"
			class="dashboard-overlay w-100"
		>
			<section
				class="w-100 h-100"
			>
				<div>
					<b-row class="section-title">
						<div>
							<div class="title-chart">{{ title }} {{ titleAdd }}</div>
						</div>
					</b-row>
				</div>
				<div class="body-chart">
					<ECharts
						:autoresize="true"
						v-if="ready"
						:options="chartOptions"
						class="echart"
					/>
				</div>
			</section>
		</b-overlay>
	</b-row>
</template>

<script>
	import ECharts from "vue-echarts";
	import "echarts/lib/chart/line";
	import "echarts/lib/chart/bar";
	import "echarts/lib/chart/pie";
	import "echarts/lib/component/axis";
	import "echarts/lib/component/tooltip";
	import "echarts/lib/component/dataZoom";
	import "echarts/lib/component/legend";
	import { ChartsService } from "@/services/charts";
	import { numberFormatted } from "@/helpers/common";

	const colors = [
		"orange",
		"gray",
		"green",
		"blue",
		"pink",
		"cyan",
		"red",
		"yellow",
		"violet"
	];

	export default {
		components: {
			ECharts
		},

		props: {
			title: {
				type: String,
				required: false,
				default: ""
			},
			searchDate: {
				type: Date,
				required: false
			},
			showZoom: {
				type: Boolean,
				required: false,
				default: false
			},
			showXAxisLabel: {
				type: Boolean,
				required: false,
				default: false
			},
			showIncome: {
				type: Boolean,
				required: false,
				default: true
			},
			chartKey: {
				type: Number,
				required: true
			},
			showLegend: {
				type: Boolean,
				required: false,
				default: true
			},
			forceUpdate: {
				type: Number,
				required: false,
				default: 0
			},
			filters: {
				type: Object,
				required: false
			}

		},

		data () {
			return {
				chartsService: new ChartsService(),
				ready: true,
				months: [
					{
						nome: "Janeiro",
						value: 1
					}, {
						nome: "Fevereiro",
						value: 2
					}, {
						nome: "Março",
						value: 3
					}, {
						nome: "Abril",
						value: 4
					}, {
						nome: "Maio",
						value: 5
					}, {
						nome: "Junho",
						value: 6
					}, {
						nome: "Julho",
						value: 7
					}, {
						nome: "Agosto",
						value: 8
					}, {
						nome: "Setembro",
						value: 9
					}, {
						nome: "Outubro",
						value: 10
					}, {
						nome: "Novembro",
						value: 11
					}, {
						nome: "Dezembro",
						value: 12
					}
				],
				monthsSelected: [],
				yearsSelected: [],
				groupingSelected: [],
				typeChartSelected: null,
				chartOptions: {
					notMerge: true,
					dataZoom: [{
						id: "dataZoomX",
						type: "slider",
						xAxisIndex: [0],
						filterMode: "empty",
						top: "15px",
						show: this.showZoom
					}],
					title: {
						text: ""
					},
					tooltip: {
						trigger: "item",
						formatter: params => `
							${params.marker}
							${params.seriesName} ${params.name}<br>
							<div style="display: flex; justify-content: center;"><span>R$ ${numberFormatted(params.value)}</span></div>
						`
					},
					legend: {
						show: this.showLegend,
						data: [],
						bottom: "10px",
						right: "48px",
						textStyle: {
							color: "black",
							fontFamily: "Roboto Condensed Regular"
						}
					},
					grid: {
						left: "64px",
						right: "48px",
						bottom: "64px",
						top: "64",
						containLabel: false
					},
					xAxis: {
						type: "category",
						data: [],
						axisLabel: {
							show: this.showXAxisLabel,
							textStyle: {
								color: "black",
								fontFamily: "Roboto Condensed Regular",
								fontSize: 10
							},
							rotate: 30
						}
					},
					yAxis: {
						type: "value",
						axisLabel: {
							show: true,
							textStyle: {
								color: "black",
								fontFamily: "Roboto Condensed Regular",
								fontSize: 10
							},
							formatter: params => `${numberFormatted(params)}`,
							rotate: 0
						}
					},
					series: []
				},
				titleAdd: ""
			};
		},

		watch: {
			"searchDate" () {
				this.findMediaChart();
			},

			"showIncome" () {
				this.findMediaChart();
			},
			"chartKey" () {
				this.findMediaChart();
			},
			"forceUpdate" () {
				this.findMediaChart();
			},
			"filters" () {
				this.monthsSelected = this.filters.monthsSelected;
				this.yearsSelected = this.filters.yearsSelected;
				this.groupingSelected = this.filters.groupingSelected;
				this.typeChartSelected = this.filters.typeChartSelected;
				this.findMediaChart();
			}
		},

		async mounted () {
			if (!this.filters || !this.filters.yearsSelected) {
				const currentYear = ((new Date()).getFullYear()).toString();
				this.yearsSelected = [{
					nome: currentYear,
					value: currentYear
				}];

				this.groupingSelected = [{
					nome: "Categoria",
					value: 1
				}];

				this.typeChartSelected = "bar";
			}

			this.chartOptions.grid.top = this.showZoom ? "64px" : "32px";
			await this.findMediaChart();
		},

		methods: {
			initChartOptions () {
				this.chartOptions = {};
				return this.chartOptions = {
					notMerge: true,
					dataZoom: [{
						id: "dataZoomX",
						type: "slider",
						xAxisIndex: [0],
						filterMode: "empty",
						top: "15px",
						show: this.showZoom
					}],
					title: {
						text: "Ventas de Productos"
					},
					tooltip: {
						trigger: "item",
						formatter: params => `
							${params.marker}
							${params.seriesName} ${params.name}<br>
							<div style="display: flex; justify-content: center;"><span>R$ ${numberFormatted(params.value)}</span></div>
						`
					},
					legend: {
						show: this.showLegend,
						data: [],
						bottom: "10px",
						right: "48px",
						textStyle: {
							color: "black",
							fontFamily: "Roboto Condensed Regular"
						}
					},
					grid: {
						left: "64px",
						right: "48px",
						bottom: "64px",
						top: "64",
						containLabel: false
					},
					xAxis: {
						type: "category",
						data: [],
						axisLabel: {
							show: this.showXAxisLabel,
							textStyle: {
								color: "black",
								fontFamily: "Roboto Condensed Regular",
								fontSize: 10
							},
							rotate: 30
						}
					},
					yAxis: {
						type: "value",
						axisLabel: {
							show: true,
							textStyle: {
								color: "black",
								fontFamily: "Roboto Condensed Regular",
								fontSize: 10
							},
							formatter: params => `${numberFormatted(params)}`,
							rotate: 0
						}
					},
					series: []
				};
			},

			async findMediaChart () {
				try {
					await this.initChartOptions();
					let months = "";
					let years = "";

					if (this.searchDate) {
						months = [ (this.searchDate.getMonth()) + 1 ];
						years = [ this.searchDate.getFullYear() ];
					} else {
						months = this.monthsSelected.length && this.monthsSelected.map((m) => m.value);
						years = this.yearsSelected.length && this.yearsSelected.map((y) => y.value);
					}

					const filters = {
						months,
						years,
						chartKey: this.chartKey
					};
					this.ready = true;
					this.chartOptions.series = [];
					this.chartOptions.xAxis.data = [];
					this.chartOptions.legend.data = [];
					const chartData = await this.chartsService.mediaChart({filters});
					const chartDataProcess = [];
					switch (this.chartKey) {
						case 2:
							chartData.map((ele) => {
								const chartRecords = {};
								const month = parseInt(ele.month) - 1;
								const month_char = this.months[month].nome;
								chartRecords.serie = `${month_char} ${ele.year}`;
								chartRecords.expenses = ele.expenses;
								if (this.showIncome) chartRecords.income = ele.income;

								return chartDataProcess.push(chartRecords);
							}, []);

							this.chartOptions.xAxis.data = chartDataProcess.map(data => data.serie);
							if (this.showIncome) {
								this.chartOptions.series.push({
									name: "Receitas",
									type: this.typeChartSelected,
									color: colors[2],
									data: chartDataProcess.map(value => value.income),
									label: {
										show: true,
										position: "top",
										fontSize: 9,
										fontFamily: "Roboto Condensed Regular",
										formatter: params => `${numberFormatted(params.value)}`,
										color: "black"
									}
								});
							}
							this.chartOptions.series.push({
								name: "Despesas",
								type: this.typeChartSelected,
								color: colors[0],
								data: chartDataProcess.map(value => value.expenses),
								label: {
									show: true,
									position: "top",
									fontSize: 9,
									fontFamily: "Roboto Condensed Regular",
									formatter: params => `${numberFormatted(params.value)}`,
									color: "black"
								}
							});
							this.chartOptions.legend.data = this.chartOptions.series.map(serie => serie.name);
							break;
						case 3:
							this.chart3Group(chartData, chartDataProcess, this.groupingSelected.length && this.groupingSelected[0].value || 1);
							break;
					}
				} catch (err) {
					console.log(err);
					this.$snotify.error(
						"Não foi possível atualizar o gráfico online. Tentando novamente...",
						{ timeout: 3000 }
					);
				}
			},

			chart3Group (chartData, chartDataProcess, grouping) {
				let primaryKey = "";
				let secundaryKey = "";

				switch (grouping) {
					case 1: // Month
						primaryKey = "monthYear";
						secundaryKey = "description";
						break;
					case 2: // cateory
						primaryKey = "description";
						secundaryKey = "monthYear";
						break;
					default:
						primaryKey = "monthYear";
						secundaryKey = "description";
				}

				const primary = [];
				const secundary = [];
				chartData.forEach((ele) => {
					const month = parseInt(ele.month) - 1;
					const month_char = `${this.months[month].nome} ${ele.year}`;
					ele.monthYear = month_char;
				});

				chartDataProcess = chartData.reduce((acc, ele) => {
					const xAxis = ele[primaryKey];

					const xAxisExist = (Object.keys(acc)).includes(xAxis);
					if (!xAxisExist) {
						acc[xAxis] = {};
					}

					const serie = ele[secundaryKey];
					acc[xAxis][serie] = ele.expenses;

					const primaryExist = primary.includes(xAxis);
					if (!primaryExist)
						primary.push(xAxis);

					const secundaryExist = secundary.includes(serie);
					if (!secundaryExist)
						secundary.push(serie);

					return acc;
				}, {});

				const chartDataEixo = secundary.reduce((acc, xAxis) => {
					acc[xAxis] = {};
					primary.forEach((serie) => {
						acc[xAxis][serie] = chartDataProcess[serie] && chartDataProcess[serie][xAxis] || "0.00";
					});
					return acc;
				}, {});

				const qtdColors = colors.length;
				let idxColor = 0;
				this.chartOptions.series = [];
				primary.forEach((ele) => {
					this.chartOptions.series.push({
						name: ele,
						type: this.typeChartSelected,
						color: colors[idxColor],
						data: secundary.map(ser => chartDataEixo[ser][ele]),
						label: {
							show: true,
							position: "top",
							fontSize: 9,
							fontFamily: "Roboto Condensed Regular",
							formatter: params => `${numberFormatted(params.value)}`,
							color: "black"
						}
					});
					idxColor = idxColor + 1 < qtdColors ? idxColor + 1 : 0;
				});

				this.chartOptions.xAxis.data = secundary.map(ele => ele);
				this.chartOptions.legend.data = primary.map(ele => ele);
			}

		}
	};
</script>

<style scoped>
	.section-title {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: aliceblue;
		padding-top: 1rem !important;
		padding-bottom: 3px !important;
		padding: 3px 10px;
		margin-bottom: 0px;
		margin-left: 0px;
		width: 100%;
	}
	.div-component {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.body-chart {
		width: 100%;
		height: 30rem;
		min-height: 150px;
		display: flex;
		justify-content: center;
	}

	.echart {
		width: 100%;
		background-color: aliceblue;
		height: auto;
	}

	.style-select {
		color: #28A745;
		border-color: #28A745;
		font-family: "Roboto Condensed Regular";
		font-size: 0.875rem;
	}

	.title-chart {
		font-size: 1.7rem;
		font-family: "Roboto Condensed Regular";
	}

	.select-options {
		min-width: 130px;
		max-width: 200px;
		width: 20%;
		margin-right: 5px;
	}

	.section-select {
		display: flex;
		align-items: center;
		background-color: aliceblue;
		padding-bottom: 1rem !important;
		padding: 0px 8px;
		margin-bottom: 0px;
		margin-left: 0px;
		width: 100%;
		justify-content: right;
	}

@media (max-width: 720px) {
	.title-chart {
		font-size: 1.2rem;
	}

	.body-chart {
		height: 16rem;
	}

	.style-select {
		font-size: 0.575rem;
	}
}

@media (max-width: 370px) {
	.title-chart {
		font-size: 1rem;
	}

	.body-chart {
		height: 12rem;
	}

	.style-select {
		font-size: 0.525rem;
	}

}

@media (max-width: 325px) {
	.title-chart {
		font-size: 0.9rem;
	}

	.body-chart {
		height: 10rem;
	}

	.style-select {
		font-size: 0.5rem;
	}

}

@media (max-width: 310px) {
	.title-chart {
		font-size: 0.8rem;
	}

	.body-chart {
		height: 9rem;
	}

	.style-select {
		font-size: 0.4rem;
	}

}

	@keyframes vertical-slide {
		from {
			overflow-y: clip;
			height: 0%;
		}

		to {
			overflow-y: none;
		}
	}

</style>
