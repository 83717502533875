import Axios from "axios";

import environment from "@/environment";
import { RequestInterceptor } from "./request.interceptor";

export class ChartsService {
	axios;

	constructor () {
		const interceptor = new RequestInterceptor();
		this.axios = Axios.create({ baseURL: `${environment.API}/v1` });
		this.axios.interceptors.request.use(interceptor.addAuthorizationHeader);
	}

	async mediaChart (filters) {
		const response = await this.axios.get("charts/mediaChart", { params: { filters }});
		return response.data;
	}
}
